<template>
  <div class="home">
    <p>メニューを選択してください</p>
  </div>
</template>

<script>

export default {
}
</script>
